/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100&display=swap');
* {
    // font-size: 13px;
    font-family: 'Roboto Serif', sans-serif !important;
    font-weight: 500;
    --highlight-background: black;
}

ion-item {
    --background: transparent;
}

ion-input,
ion-textarea,
ion-select {
    border: 1px solid var(--ion-color-primary);
    --padding-start: 13px !important;
    --padding-top: 13px !important;
    --padding-bottom: 13px !important;
    border-radius: 6px;
    --placeholder-color: #a1a5b7 !important;
    --placeholder-opacity: 0.9 !important;
    span {
        font-weight: 600;
        margin-left: 16px !important;
        color: #adadad;
        font-size: 17px;
    }
}