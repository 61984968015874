:root {
    /** primary **/
    --ion-color-primary: #026400;
    --ion-color-primary-rgb: 31, 37, 44;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #1b2127;
    --ion-color-primary-tint: #353b41;
    /** secondary **/
    --ion-color-secondary: #2E323A;
    --ion-color-secondary-rgb: 46, 50, 58;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #282c33;
    --ion-color-secondary-tint: #43474e;
    /** tertiary **/
    --ion-color-tertiary: #51555D;
    --ion-color-tertiary-rgb: 81, 85, 93;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #474b52;
    --ion-color-tertiary-tint: #62666d;
    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    /** medium **/
    --ion-color-medium: #97989C;
    --ion-color-medium-rgb: 151, 152, 156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #858689;
    --ion-color-medium-tint: #a1a2a6;
    /** light **/
    --ion-color-light: #FEFEFE;
    --ion-color-light-rgb: 254, 254, 254;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #fefefe;
}